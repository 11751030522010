import { Controller } from '@hotwired/stimulus'

/*
 * Prefer using DataPageSelectSearchController rather than this one as it's more generic.
 * The only difference is that this one handles the presence of a checkbox
 * and allows it to remain visible even when it does not match the search,
 * and allows an element to be hidden when a search is active.
 *
 * Eventually we will merge these two controllers.
 */
export default class DropdownSearchController extends Controller {
  static targets = ['optionList', 'hideOnSearch', 'selectOption', 'selectedValue', 'selectedValueLabel']

  connect () {
    this.selectOptionTargets.forEach(t => {
      t.dataset.abbreviation = t.dataset.textValue
        .split(/[\s_-]/)
        .filter(n => n)
        .map(w => w[0].toLowerCase())
        .join('')
    })
  }

  findMatchingData (event) {
    const searchValue = event.currentTarget.value.toLowerCase()
      .split(/[\s_-]/)
      .filter(n => n)
      .join('')
    if (searchValue) {
      this.hasHideOnSearchTarget && this.hideOnSearchTarget.classList.add('hidden')
    } else {
      this.hasHideOnSearchTarget && this.hideOnSearchTarget.classList.remove('hidden')
    }

    this.selectOptionTargets.forEach(t => {
      const hasChecked = Array.from(t.querySelectorAll('input')).filter(i => i.checked === true).length > 0
      if (hasChecked) return

      if (!searchValue || t.dataset.abbreviation.includes(searchValue) ||
        t.dataset.textValue.replace(/[\s_-]/g, '').toLowerCase().includes(searchValue)
      ) {
        t.classList.remove('hidden')
      } else {
        t.classList.add('hidden')
      }
    })
  }

  setSingleSelect (event) {
    if (this.hasSelectedValueTarget) {
      this.selectedValueTarget.value = event.currentTarget.dataset.singleSelectValue
    }
    if (this.selectedValueLabelTarget) {
      this.selectedValueLabelTarget.textContent = event.currentTarget.dataset.textValue
    }
    for (const input of this.element.querySelectorAll('input[type="checkbox"]')) {
      input.checked = false
    }
    for (const input of this.element.querySelectorAll('input[type="radio"]')) {
      input.checked = input.value === event.currentTarget.dataset.singleSelectValue
    }
  }

  resetDefaults (event) {
    for (const input of this.element.querySelectorAll('input[type="radio"]')) {
      if (input.dataset.selectType === 'default') {
        input.checked = false
      }
    }
  }
}
