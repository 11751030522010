import BaseGraph from './base_graph_controller'
import { setAnnotations, newAnnotation } from '../helpers/annotations_helper'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class LeadTimeBreakdownByStageChartController extends BaseGraph {
  static outlets = ['date-range']

  connect () {
    super.connect()

    window.addEventListener('lead-time-breakdown-by-stage-chart-legend-select', this.legendClicked.bind(this))
    window.addEventListener('lead-time-breakdown-by-stage-chart-legend-all-option-select', this.legendAllOptionClicked.bind(this))
    window.addEventListener('lead-time-breakdown-by-stage-chart-legend-mouse-over', this.legendMouseOver.bind(this))
    window.addEventListener('lead-time-breakdown-by-stage-chart-legend-mouse-out', this.legendMouseOut.bind(this))
  }

  prepareGraphSchema () {
    const controller = this

    const sequenceColors = controller.darkMode ? controller.tailwindColors['sequence-dark'] : controller.tailwindColors['sequence-light']

    const colors = [
      sequenceColors[1],
      sequenceColors[2],
      sequenceColors[3],
      sequenceColors[4],
      sequenceColors[5]
    ]

    const seriesKeys = Object.keys(controller.parsedGraphData)

    if (!this.visibleItems) {
      this.visibleItems = {}
      seriesKeys.forEach(key => { controller.visibleItems[key] = true })
    }

    let total = 0
    const seriesData = seriesKeys.map((key, i) => {
      const data = controller.parsedGraphData[key].data
      let color = colors[i]
      const keySeriesTotal = data.reduce((leadTimeTotal, [, leadTimeRow]) => leadTimeTotal + leadTimeRow, 0)
      total += keySeriesTotal
      if (key === 'deploy' && keySeriesTotal === 0) {
        color = controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[200]
      }
      let zoneValues = {}
      if (keySeriesTotal > 0) {
        zoneValues = {
          zoneAxis: 'x',
          zones: [
            {
              value: data.length > 2 ? data[data.length - 2][0] : null,
              fillColor: color,
              color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white'
            },
            {
              color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
              dashStyle: 'ShortDash',
              fillColor: {
                pattern: {
                  path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
                  width: 4,
                  height: 4,
                  color: color
                }
              }
            }
          ]
        }
        if (controller.optionsValue.date_granularity === 'Week') {
          const sevenDaysAgo = new Date()
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6)

          // see if the last data point is older than 7 days ago
          if (data[data.length - 1][0] < sevenDaysAgo) {
            zoneValues = {}
          }
        } else {
          const thirtyDaysAgo = new Date()
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

          // see if the last data point is older than 30 days ago
          if (data[data.length - 1][0] < thirtyDaysAgo) {
            zoneValues = {}
          }
        }
      }

      return {
        ...controller.parsedGraphData[key],
        fillColor: color,
        color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
        lineWidth: 2,
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: color,
          lineColor: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
          lineWidth: 1
        },
        type: 'areaspline',
        stacking: 'normal',
        legendIndex: i,
        visible: controller.visibleItems[key],
        ...zoneValues
      }
    })

    const plotLines = []
    if (total > 0) {
      plotLines.push({
        color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[600],
        value: controller.optionsValue.average === 0 ? null : controller.optionsValue.average,
        width: 2,
        dashStyle: 'ShortDash',
        zIndex: 5
      })
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      annotations: setAnnotations(controller),
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 0,
        height: 364,
        zooming: { type: 'x' },
        events: {
          load: function () {
            this.series.forEach(series => {
              if (series?.points?.length === 1) {
                series.points[0].update({
                  marker: {
                    enabled: true
                  }
                })
              }
            })
          },
          selection: function (event) {
            if (controller.hasDateRangeOutlet && event.xAxis) {
              controller.dateRangeOutlet.newDateSelect(
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].min),
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].max)
              )
            }
            return false
          }
        }
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[600]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[200]
      },
      yAxis: {
        title: {
          text: null
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[600]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          trackByArea: true,
          fillOpacity: 1,
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          events: {
            legendItemClick: () => { return false },
            click: (event) => { newAnnotation(controller, event) }
          }
        }
      },
      series: [
        ...seriesData.reverse(),
        {
          name: `${controller.optionsValue.date_granularity}ly avg`,
          color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[600],
          type: 'line',
          dashStyle: 'ShortDash',
          data: [],
          legendIndex: 6
        }
      ],
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const startDate = new Date(this.x) // get current date
          let formattedDate = ''

          if (controller.optionsValue.date_granularity === 'Week') {
            const endOfWeek = new Date(this.x)
            endOfWeek.setDate(startDate.getDate() + 6)
            formattedDate = `${Highcharts.dateFormat('%b %e', startDate)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`
          } else { // Month
            formattedDate = Highcharts.dateFormat("%b '%y", startDate)
          }

          const breakDownValues = this.points.map((p) => {
            return breakDownValue({
              name: p.series.name,
              value: controller.daysOrHoursString(p.y),
              style: `background-color: ${p.series.options.fillColor};`,
              type: 'box'
            })
          }).reverse()

          return card({
            date: formattedDate,
            breakDownValues: [...breakDownValues, breakDownValue({
              name: `${controller.optionsValue.date_granularity}ly avg`,
              value: controller.daysOrHoursString(controller.optionsValue.average || 0),
              cssClass: 'text-blue-800 dark:text-blue-300',
              type: 'dash'
            })]
          })
        }
      }
    }
  }

  legendClicked (event) {
    const seriesName = event.detail.name
    const series = this.chart.series.find(series => series.name === seriesName)
    // the false asks the chart not to re-render
    series?.setVisible(!series.visible, false)
    this.visibleItems[seriesName] = series?.visible

    this.showHidePlotlines()
  }

  legendAllOptionClicked (event) {
    const state = event.detail.state
    this.chart.series?.forEach(series => {
      // the false asks the chart not to re-render
      series?.setVisible(state, false)
    })

    Object.keys(this.visibleItems).forEach(key => {
      this.visibleItems[key] = state
    })

    // re-render the chart
    this.chart.redraw()

    this.showHidePlotlines()
  }

  legendMouseOver (event) {
    const seriesName = event.detail.name
    this.chart.series?.forEach(series => {
      if (series?.name === seriesName) { return }
      series?.setState('inactive')
    })
  }

  legendMouseOut () {
    this.chart.series?.forEach(series => { series?.setState('') })
  }

  showHidePlotlines () {
    const plotLines = [
      {
        color: this.darkMode ? this.tailwindColors.blue[300] : this.tailwindColors.blue[800],
        value: this.optionsValue.average === 0 ? null : this.optionsValue.average,
        width: 2,
        dashStyle: 'ShortDash',
        zIndex: 5
      }
    ]

    // check if all the series are visible
    const allVisible = Object.keys(this.visibleItems).every(key => this.visibleItems[key])
    const avgSeries = this.chart.series.find(series => series.name.includes('ly avg'))
    if (allVisible) {
      avgSeries?.setVisible(true, false)
      this.chart.yAxis[0].update({ plotLines })
    } else {
      avgSeries?.setVisible(false, false)
      this.chart.yAxis[0].update({ plotLines: [] })
    }
  }
}
