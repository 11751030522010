import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class KpiReportFeaturesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const seriesKeys = [
      {
        key: 'features',
        name: 'New',
        color: this.categoricalColors[1],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'churn',
        name: 'Churn',
        color: this.categoricalColors[2],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'refactor',
        name: 'Refactor',
        color: this.categoricalColors[3],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'other',
        name: 'Other',
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'features_per_engineer',
        name: 'New Units per Developer',
        lineColor: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
        color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
        type: 'spline',
        yAxis: 1
      }
    ]

    controller.tooltipValues = {}
    controller.parsedGraphData.categories.forEach((category, i) => {
      controller.tooltipValues[category] = { ...controller.parsedGraphData.data[i] }
    })

    let fpeMax = 0
    let totalFeaturesMax = 0

    const series = seriesKeys
      .map((config, i) => {
        const seriesData = controller.parsedGraphData.data.map((data) => data.series[config.key])
        let seriesConfig = {}
        const seriesTotal = seriesData.length ? seriesData.reduce((acc, curr) => acc + curr, 0) : 0
        if (config.key === 'features_per_engineer') {
          fpeMax = seriesTotal
          let zones = []
          if (this.parsedGraphData.partial_end_data) {
            zones = [{
              value: seriesData.length > 1 ? seriesData.length - 2 : 0,
              color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500]
            }, {
              dashStyle: 'ShortDash',
              color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500]
            }]
          }
          seriesConfig = {
            zones,
            zoneAxis: 'x'
          }
        } else {
          totalFeaturesMax = Math.max(totalFeaturesMax, seriesTotal)
          if (controller.parsedGraphData.partial_end_data) {
            seriesData[seriesData.length - 1] = { y: seriesData[seriesData.length - 1], ...this.getLastDataPointPattern(config) }
          }
          seriesConfig = {
            stacking: 'normal',
            states: {
              hover: { brightness: controller.darkMode ? -0.1 : 0.05 }
            }
          }
        }

        return {
          data: seriesData,
          lineColor: config.color,
          zIndex: 1,
          marker: {
            enabled: false
          },
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          ...seriesConfig,
          ...config
        }
      })

    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(fpeMax)
    const totalFeaturesMaxSettings = this.getYAxisMaxAndTickAmountSettings(totalFeaturesMax)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && totalFeaturesMax === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...totalFeaturesMaxSettings
        },
        {
          title: { text: null },
          gridLineWidth: 0,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && fpeMax === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...fpeMaxSettings
        }
      ],
      plotOptions: {
        column: {
          pointPadding: 0.025,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal'
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            if (p.series.name === 'No data') {
              return null
            }
            const featureCount = Math.round(p.y * 10) / 10
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${color};`,
              value: p.series.name === 'New Units / Developer' ? featureCount : `${featureCount} (${Math.round(p.percentage)}%)`,
              type: p.series.name === 'New Units / Developer' ? 'line' : 'box'
            })
          }).reverse().filter(p => p)

          return card({
            date: controller.tooltipValues[this.key].series.date_range_display,
            breakDownValues,
            incomplete: controller.parsedGraphData.partial_end_data && this.points[0].point.index === controller.parsedGraphData.categories.length - 1,
            granularity: controller.optionsValue.date_granularity
          })
        }
      }
    }
  }
}
