import BaseGraph from './base_graph_controller'

// Connects to data-controller="cost-efficiency-chart"
export default class CostEfficiencyChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const USDFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })

    if (!this.categoryVisibility) {
      this.categoryVisibility = {}
      controller.parsedGraphData.categories.forEach(category => { controller.categoryVisibility[category] = true })
    }

    const visibleCategories = this.parsedGraphData.categories.filter(category => this.categoryVisibility[category])
    const visibleData = this.parsedGraphData.data.filter(dataPoint => this.categoryVisibility[dataPoint[0]])

    return {
      chart: {
        type: 'column',
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        spacingBottom: 10,
        height: 364
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: visibleCategories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          // add $ in front of y-axis labels
          formatter: function () {
            return USDFormatter.format(this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent',
          colorByPoint: true,
          maxPointWidth: 250
        }
      },
      colors: this.breakdownColors,
      series: [{ data: visibleData }],
      tooltip: {
        ...this.baseToolTipConfig,
        formatter: function () {
          return `<div class="shadow-lg p-2 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
            <div class="font-medium text-gray-700 dark:text-gray-300">Cost per New Unit</div>
            <div class="flex justify-between gap-1 mt-2">
              <div class="flex items-center gap-1">
                <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                <div class="font-medium text-black dark:text-white">${this.point.category}</div>
              </div>
              <div class="font-semibold text-black dark:text-white">${USDFormatter.format(this.point.y)}</div>
            </div>
          </div>
          `
        }
      }
    }
  }

  legendMouseOver (category) {
    // highlight data point in first series
    const point = this.chart.series[0].data.find(dataPoint => dataPoint.category === category)
    point?.setState('hover')
    if (point) this.chart.tooltip.refresh(point)
  }

  legendMouseOut (category) {
    // unhighlight data point in first series
    const point = this.chart.series[0].data.find(dataPoint => dataPoint.category === category)
    point?.setState('normal')
    this.chart.tooltip.hide()
  }

  legendClicked (category) {
    // toggle visibility of category
    this.categoryVisibility[category] = !this.categoryVisibility[category]
    this.chart.update(this.prepareGraphSchema())
  }

  legendAllOptionClicked (checked) {
    // toggle visibility of all categories
    Object.keys(this.categoryVisibility).forEach(category => { this.categoryVisibility[category] = checked })
    this.chart.update(this.prepareGraphSchema())
  }
}
