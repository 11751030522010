import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class LeadTimePullRequestsChartController extends BaseGraph {
  static outlets = ['date-range']

  prepareGraphSchema () {
    const controller = this

    let zoneValues = {
      zoneAxis: 'x',
      zones: [
        {
          value: controller.parsedGraphData.lead_time_data.length > 2
            ? controller.parsedGraphData.lead_time_data[controller.parsedGraphData.lead_time_data.length - 2][0]
            : 0,
          lineColor: controller.tailwindColors.blue[500]
        },
        {
          lineColor: controller.tailwindColors.blue[500],
          dashStyle: 'ShortDash',
          fillColor: {
            pattern: {
              path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
              width: 4,
              height: 4,
              color: Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(0.1)
                .get('rgba')
            }
          }
        }
      ]
    }
    const data = controller.parsedGraphData.lead_time_data
    if (data.length) {
      if (controller.optionsValue.date_granularity === 'Week') {
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6)

        // see if the last data point is older than 7 days ago
        if (data[data.length - 1][0] < sevenDaysAgo) {
          zoneValues = {}
        }
      } else {
        const thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

        // see if the last data point is older than 30 days ago
        if (data[data.length - 1][0] < thirtyDaysAgo) {
          zoneValues = {}
        }
      }
    }

    const total = data.reduce((acc, arr) => { return acc + arr[1] }, 0)
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 0,
        height: 364,
        zooming: { type: 'x' },
        events: {
          load: function () {
            this.series.forEach(series => {
              if (series?.points?.length === 1) {
                series.points[0].update({
                  marker: {
                    enabled: true
                  }
                })
              }
            })
          },
          selection: function (event) {
            if (controller.hasDateRangeOutlet && event.xAxis) {
              controller.dateRangeOutlet.newDateSelect(
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].min),
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].max)
              )
            }
            return false
          }
        }
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[600]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[200]
      },
      yAxis: {
        title: {
          text: null
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[600]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        plotLines: [
          {
            color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[800],
            value: controller.parsedGraphData.average === 0 ? null : controller.parsedGraphData.average,
            width: 2,
            dashStyle: 'ShortDash',
            zIndex: 5
          }
        ],
        ...maxSettings
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          trackByArea: true,
          borderWidth: 0,
          dataLabels: { enabled: false },
          marker: { enabled: false, symbol: 'circle' },
          events: {
            legendItemClick: () => { return false }
          }
        }
      },
      series: [
        {
          name: 'Lead time',
          data: controller.parsedGraphData.lead_time_data,
          lineColor: controller.tailwindColors.blue[500],
          color: controller.tailwindColors.blue[500],
          type: 'areaspline',
          linkedTo: 'lead-time-line',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [
                0,
                Highcharts.color(controller.tailwindColors.blue[500])
                  .setOpacity(controller.darkMode ? 0.3 : 0.2)
                  .get('rgba')
              ],
              [
                1,
                Highcharts.color(controller.tailwindColors.blue[500])
                  .setOpacity(0)
                  .get('rgba')
              ]
            ]
          },
          ...zoneValues
        },
        {
          name: 'Lead time',
          color: controller.tailwindColors.blue[500],
          type: 'line',
          id: 'lead-time-line'
        },
        {
          name: `${controller.optionsValue.date_granularity}ly avg`,
          color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[800],
          type: 'line',
          dashStyle: 'ShortDash',
          data: []
        }
      ],
      tooltip: {
        ...controller.baseToolTipConfig,
        formatter: function () {
          const startDate = new Date(this.x) // get current date
          let formattedDate = ''

          if (controller.optionsValue.date_granularity === 'Week') {
            const endOfWeek = new Date(this.x)
            endOfWeek.setDate(startDate.getDate() + 6)
            formattedDate = `${Highcharts.dateFormat('%b %e', startDate)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`
          } else { // Month
            formattedDate = Highcharts.dateFormat('%b \'%y', startDate)
          }

          return card({
            date: formattedDate,
            primaryValues: [
              primaryValue({ name: 'Lead Time', value: controller.daysOrHoursString(this.y) })
            ],
            breakDownValues: [
              breakDownValue({
                name: `${controller.optionsValue.date_granularity}ly avg`,
                value: controller.daysOrHoursString(controller.parsedGraphData.average || 0),
                cssClass: 'text-blue-800 dark:text-blue-300',
                type: 'dash'
              })
            ]
          })
        }
      }
    }
  }
}
