import BaseGraph from './base_graph_controller'
import { card, breakDownValue, percentChangeDiv } from '../helpers/tooltip_helper'
import { linearGradientConfig } from '../helpers/fill_color_helper'
import { currentPeriodPlotBand } from '../helpers/current_period_plot_band_helper'
import { benchmarkPlotLines } from '../helpers/benchmark_plot_line_helper'
import { fetchSelectedGraphPeriod } from '../helpers/kpi_graph_helper'

export default class KpiTrendGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    let max = this.parsedGraphData.max
    const zoneOneLimit = this.parsedGraphData.series_data.length > 1 ? this.parsedGraphData.series_data.length - 2 : 0
    let zones = []
    if (this.parsedGraphData.partial_end_data) {
      zones = [{
        value: zoneOneLimit,
        ...linearGradientConfig(controller)
      }, {
        dashStyle: 'ShortDash',
        ...linearGradientConfig(controller)
      }]
    } else {
      zones = [{
        ...linearGradientConfig(controller)
      }]
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(max)

    if (controller.optionsValue.benchmark_data?.value && controller.optionsValue.benchmark_data.value > max) {
      max = controller.optionsValue.benchmark_data.value
      maxSettings.max = max + (max * 0.1)
    }

    return {
      chart: {
        sync: true,
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0,
        plotBands: this.parsedGraphData.series_data.length > 1 && currentPeriodPlotBand(controller, controller.parsedGraphData.comparison_index)
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: '',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && max === 0) {
              return '--'
            } else if (controller.parsedGraphData.currency) {
              return `$${controller.formatNumberWithCommas(this.value)}`
            } else {
              return `${controller.formatNumberWithCommas(this.value)}`
            }
          }
        },
        startOnTick: false,
        plotLines: benchmarkPlotLines(controller),
        ...maxSettings
      },
      plotOptions: {
        series: {
          trackByArea: true,
          connectNulls: true,
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                fetchSelectedGraphPeriod({
                  selectedIndex: this.index,
                  parsedGraphData: controller.parsedGraphData,
                  graphUrl: controller.optionsValue.url
                })
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          let percentDiff = null
          if (this.point.index > 0) {
            const previousValue = this.series.data[this.point.index - 1].y
            percentDiff = ((parseFloat(this.y) - parseFloat(previousValue)) / Math.abs(previousValue)) * 100
          }
          const changeDiv = percentChangeDiv(percentDiff, controller.parsedGraphData.is_lower_better)
          const breakDownValues = [
            breakDownValue({
              name: this.series.name,
              value: controller.formatNumberWithCommas(controller.parsedGraphData.currency ? `$${this.y}` : this.y) + changeDiv,
              style: `background-color: ${this.color};`,
              type: 'line'
            })
          ]
          return card({
            date: this.key,
            breakDownValues
          })
        },
        style: {
          zIndex: 100
        }
      },
      series: [
        {
          data: controller.parsedGraphData.series_data,
          marker: {
            enabled: false
          },
          lineColor: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500],
          color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500],
          type: 'areaspline',
          name: this.parsedGraphData.name,
          zoneAxis: 'x',
          zones
        }
      ]
    }
  }

  fetchSelectedGraphPeriodForTooltipButton (event) {
    fetchSelectedGraphPeriod({
      selectedIndex: Number(event.target.dataset.value),
      parsedGraphData: this.parsedGraphData,
      graphUrl: this.optionsValue.url
    })
  }
}
