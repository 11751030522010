import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'
import { currentPeriodPlotBand } from '../helpers/current_period_plot_band_helper'
import { fetchSelectedGraphPeriod } from '../helpers/kpi_graph_helper'

export default class KpiContributorsGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    controller.seriesKeys = [
      {
        key: 'developers',
        name: 'Developers',
        color: controller.darkMode ? controller.tailwindColors.blue[500] : controller.tailwindColors.blue[200]
      },
      {
        key: 'reviewers',
        name: 'Reviewers only',
        color: controller.darkMode ? controller.tailwindColors.purple[400] : controller.tailwindColors.purple[300]
      }
    ]

    const series = controller.seriesKeys.map((config, i) => {
      const data = controller.parsedGraphData[config.key]

      const zoneOneLimit = data.length > 1 ? data.length - 2 : 0
      let zones = []
      if (this.parsedGraphData.partial_end_data) {
        zones = [{
          value: zoneOneLimit,
          fillColor: config.color,
          color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white'
        }, {
          dashStyle: 'ShortDash',
          fillColor: {
            pattern: {
              path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
              width: 4,
              height: 4,
              color: config.color
            }
          },
          color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white'
        }]
      }
      return {
        data,
        name: config.name,
        fillColor: config.color,
        color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
        lineWidth: 2,
        zIndex: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: config.color,
          lineColor: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
          lineWidth: 1
        },
        stacking: 'normal',
        legendIndex: i,
        zoneAxis: 'x',
        zones
      }
    })

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    return {
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        margin: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0,
        plotBands: currentPeriodPlotBand(controller, controller.parsedGraphData.comparison_index)
      },
      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...maxSettings
        }
      ],
      plotOptions: {
        series: {
          trackByArea: true,
          borderWidth: 0,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          stacking: 'stream',
          point: {
            events: {
              click: function () {
                fetchSelectedGraphPeriod({
                  selectedIndex: this.index,
                  parsedGraphData: controller.parsedGraphData,
                  graphUrl: controller.optionsValue.url
                })
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.map((p) => {
            return breakDownValue({
              name: p.series.name,
              value: p.y === null ? '--' : p.y,
              style: `background-color: ${p.series.options.fillColor};`,
              type: 'box'
            })
          })

          return card({
            date: this.key,
            primaryValues: [
              primaryValue({ name: 'Contributors', value: this.total })
            ],
            breakDownValues
          })
        }
      }
    }
  }

  fetchSelectedGraphPeriodForTooltipButton (event) {
    fetchSelectedGraphPeriod({
      selectedIndex: Number(event.target.dataset.value),
      parsedGraphData: this.parsedGraphData,
      graphUrl: this.optionsValue.url
    })
  }
}
