import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class TeamCodeChangeRateGraph extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const colorConfig = {
      bg: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[200],
      hover: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
    }
    // team view currently only supports weekly data
    const tickInterval = 60 * 60 * 24 * 1000 * 7
    const seriesData = []

    for (let i = 0; i < controller.parsedGraphData.series.length; i++) {
      let data = controller.parsedGraphData.series[i]
      if (i === controller.parsedGraphData.series.length - 1) {
        data = { ...data, ...this.getLastDataPointPattern(colorConfig) }
      }
      seriesData.push(data)
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    const series = [{
      name: controller.parsedGraphData.name,
      data: seriesData,
      zIndex: 1,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      legendIndex: 0,
      minPointLength: 0,
      yAxis: 0,
      color: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[200],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
        }
      },
      marker: { enabled: false }
    }]

    let plotLines = []
    if (controller.parsedGraphData.weekly_avg !== 0) {
      plotLines = [
        {
          zIndex: 50,
          color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
          value: controller.parsedGraphData.weekly_avg,
          dashStyle: 'ShortDash',
          width: 2
        }
      ]
    }

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        tickInterval: tickInterval,
        type: 'datetime',
        labels: {
          autoRotation: false,
          step: seriesData.length > 6 ? 3 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return Highcharts.dateFormat(controller.parsedGraphData.date_format, this.value)
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !controller.parsedGraphData.max) {
              return '--'
            } else {
              return `${this.value}%`
            }
          }
        },
        reversedStacks: false,
        startOnTick: false,
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false,
          trackByArea: true,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0], Number(this.y))
                }
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          return card({
            date: controller.parsedGraphData.date_range_display[this.x],
            incomplete: this.points[0].point.index === (seriesData.length - 1),
            primaryValues: [
              primaryValue({ name: controller.parsedGraphData.name, value: `${controller.getNumericDisplay(this.y)}%` })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series
    }
  }
}
