import BaseGraph from './base_graph_controller'
import { setAnnotations, newAnnotation } from '../helpers/annotations_helper'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class LeadTimeBreakdownChartController extends BaseGraph {
  static outlets = ['date-range']

  connect () {
    super.connect()

    window.addEventListener('lead-time-breakdown-chart-legend-select', this.legendClicked.bind(this))
    window.addEventListener('lead-time-breakdown-chart-legend-all-option-select', this.legendAllOptionClicked.bind(this))
    window.addEventListener('lead-time-breakdown-chart-legend-mouse-over', this.legendMouseOver.bind(this))
    window.addEventListener('lead-time-breakdown-chart-legend-mouse-out', this.legendMouseOut.bind(this))
  }

  prepareGraphSchema () {
    const controller = this

    let minTickInterval = 604800000 // 7 days in ms
    let updatedMinTickInterval = false
    if (!this.visibleItems) {
      this.visibleItems = {}
      controller.parsedGraphData.series.forEach(seriesObject => {
        controller.visibleItems[seriesObject.name] = seriesObject.visible || true
        if (!updatedMinTickInterval && seriesObject.data?.length > 1) {
          minTickInterval = seriesObject.data[0][0] - seriesObject.data[1][0]
          updatedMinTickInterval = true
        }
      })
    }

    let showZones = true
    let series = [{}]
    const data = controller.parsedGraphData.series.length ? controller.parsedGraphData.series[0].data : []
    if (data.length) {
      if (controller.optionsValue.date_granularity === 'Week') {
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6)

        // see if the last data point is older than 7 days ago
        if (data[data.length - 1][0] < sevenDaysAgo) {
          showZones = false
        }
      } else {
        const thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

        // see if the last data point is older than 30 days ago
        if (data[data.length - 1][0] < thirtyDaysAgo) {
          showZones = false
        }
      }
      series = controller.parsedGraphData.series.map(seriesObject => {
        seriesObject.visible = controller.visibleItems[seriesObject.name]
        seriesObject.showInLegend = controller.visibleItems[seriesObject.name]
        if (showZones && seriesObject.data.length > 2) {
          seriesObject.zoneAxis = 'x'
          seriesObject.zones = [
            {
              value: seriesObject.data[seriesObject.data.length - 2][0],
              fillColor: Highcharts.color(seriesObject.color).setOpacity(controller.darkMode ? 0.75 : 1).get('rgba')
            },
            {
              lineColor: seriesObject.color,
              dashStyle: 'ShortDash'
            }
          ]
        }

        return seriesObject
      })
    }

    const total = data.reduce((acc, arr) => { return acc + arr[1] }, 0)
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      annotations: setAnnotations(controller),
      chart: {
        type: 'areaspline', // using this instead of spline to make the legend markers better
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        spacingBottom: 0,
        height: 364,
        events: {
          load: function () {
            this.series.forEach(series => {
              if (series?.points?.length === 1) {
                series.points[0].update({
                  marker: {
                    enabled: true
                  }
                })
              }
            })
          },
          selection: function (event) {
            if (controller.hasDateRangeOutlet && event.xAxis) {
              controller.dateRangeOutlet.newDateSelect(
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].min),
                Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].max)
              )
            }
            return false
          }
        },
        zooming: { type: 'x' }
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        minTickInterval: minTickInterval,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[200]
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        ...maxSettings
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          trackByArea: true,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          fillOpacity: 0,
          events: {
            legendItemClick: () => { return false },
            click: (event) => { newAnnotation(controller, event) }
          }
        }
      },
      series,
      colors: this.breakdownColors,
      tooltip: {
        ...this.baseToolTipConfig,
        formatter: function () {
          const startDate = new Date(this.x) // get current date
          let formattedDate = ''

          if (controller.optionsValue.date_granularity === 'Week') {
            const endOfWeek = new Date(this.x)
            endOfWeek.setDate(startDate.getDate() + 6)
            formattedDate = `${Highcharts.dateFormat('%b %e', startDate)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`
          } else { // Month
            formattedDate = Highcharts.dateFormat("%b '%y", startDate)
          }

          return card({
            date: formattedDate,
            breakDownValues: [breakDownValue({
              name: this.series.name,
              value: controller.daysOrHoursString(this.y),
              style: `background-color:${this.series.color};`,
              type: 'box'
            })]
          })
        }
      }
    }
  }

  legendClicked (event) {
    const seriesName = event.detail.name
    const series = this.chart.series?.find(series => series.name === seriesName)
    // the false asks the chart not to re-render
    series?.setVisible(!series.visible, false)
    this.visibleItems[seriesName] = series?.visible
    series?.update({ showInLegend: series?.visible })
  }

  legendAllOptionClicked (event) {
    const state = event.detail.state
    this.chart.series?.forEach(series => {
      // the false asks the chart not to re-render
      series?.setVisible(state, false)
      // the false asks the chart not to re-render
      series?.update({ showInLegend: state }, false)
    })

    Object.keys(this.visibleItems).forEach(key => {
      this.visibleItems[key] = state
    })

    // re-render the chart
    this.chart.redraw()
  }

  legendMouseOver (event) {
    const seriesName = event.detail.name
    this.chart.series?.forEach(series => {
      if (series?.name === seriesName) { return }
      series?.setState('inactive')
    })
  }

  legendMouseOut () {
    this.chart.series?.forEach(series => { series?.setState('') })
  }
}
