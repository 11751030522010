import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class ProductivityToScaleGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const featuresPerEngineerMax = Math.max(...controller.parsedGraphData.chart.features_per_engineer)
    let featuresPerEngineer = []
    if (featuresPerEngineerMax > 0 && controller.parsedGraphData.chart.show_chart) {
      featuresPerEngineer = controller.parsedGraphData.categories.map((cat, i) => [cat, controller.parsedGraphData.chart.features_per_engineer[i]])
    }
    const activeEngineersMax = Math.max(...controller.parsedGraphData.chart.active_engineers)
    let activeEngineers = []
    if (activeEngineersMax > 0 && controller.parsedGraphData.chart.show_chart) {
      activeEngineers = controller.parsedGraphData.categories.map((cat, i) => [cat, controller.parsedGraphData.chart.active_engineers[i]])
    }

    const series = [
      {
        key: 'features_per_engineer',
        name: 'New Units / Developer',
        data: featuresPerEngineer,
        color: controller.tailwindColors.blue[500],
        lineColor: controller.tailwindColors.blue[500],
        type: 'spline',
        yAxis: 0,
        zIndex: 1,
        marker: {
          enabled: false
        },
        stacking: 'normal'
      },
      {
        key: 'active_engineers',
        name: 'Developers',
        data: activeEngineers,
        color: controller.darkMode
          ? controller.tailwindColors.purple[500]
          : controller.tailwindColors.purple[400],
        lineColor: controller.darkMode
          ? controller.tailwindColors.purple[500]
          : controller.tailwindColors.purple[400],
        type: 'line',
        step: 'center',
        yAxis: 1,
        zIndex: 1,
        marker: {
          enabled: false
        },
        stacking: 'normal'
      }
    ]

    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(featuresPerEngineerMax)
    const activeEngineersMaxSettings = this.getYAxisMaxAndTickAmountSettings(activeEngineersMax)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        plotBands: [
          {
            color: controller.darkMode
              ? 'rgba(229,231,235, 0.05)'
              : 'rgba(243,244,246, 0.5)',
            from: -0.5,
            to: 0.5,
            label: {
              align: 'center',
              y: -10,
              useHtml: true,
              formatter: function () {
                return `
                  <div class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">Previous year</div>
                `
              }
            }
          },
          {
            color: controller.darkMode
              ? 'rgba(96, 165, 250, 0.1)'
              : controller.tailwindColors.blue[50],
            from: 3.5,
            to: 4.5,
            label: {
              align: 'center',
              y: -10,
              useHtml: true,
              formatter: function () {
                return `
                  <div class="text-xs text-blue-400" style="color: ${controller.tailwindColors.blue[400]};">Selected quarter</div>
                `
              }
            }
          }
        ]
      },
      yAxis: [
        {
          title: {
            text: 'New units per developer',
            offset: 50,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            }
          },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && featuresPerEngineerMax === 0) {
                return '--'
              } else {
                return `${this.value}`
              }
            }
          },
          reversedStacks: false,
          ...fpeMaxSettings
        },
        {
          title: {
            text: 'Developers',
            offset: 50,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            }
          },
          gridLineWidth: 0,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && activeEngineersMax === 0) {
                return '--'
              } else {
                return `${this.value}`
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...activeEngineersMaxSettings
        }
      ],
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          },
          stickyTracking: false,
          trackByArea: true,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(controller.tooltipValues[this.name]).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      colors: [
        controller.tailwindColors.blue[200],
        controller.tailwindColors.orange[200],
        controller.tailwindColors.teal[200]
      ],
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${p.color};`,
              value: p.series.name === 'New units per developer' ? p.y.toFixed(1) : p.y,
              type: p.series.name === 'New units per developer' ? 'line' : 'box'
            })
          }).reverse()
          return card({
            date: this.key,
            breakDownValues
          })
        }
      }
    }
  }
}
