import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { getDateTimeStepCount } from '../helpers/axis_helper'
import { linearGradientConfig } from '../helpers/fill_color_helper'

export default class DeploymentTrendChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const total = this.parsedGraphData.data.reduce((acc, [_, value]) => acc + value, 0)
    const categories = this.parsedGraphData.data.map((data) => data[0])
    const index = controller.parsedGraphData.data.length ? controller.parsedGraphData.data.length - 2 : 0
    const zoneValue = index > 0 ? this.parsedGraphData.data[index][0] : 0
    const series = [
      {
        data: this.parsedGraphData.data,
        marker: {
          enabled: false
        },
        lineColor: this.colorTheme.primary,
        color: this.colorTheme.primary,
        type: 'areaspline',
        name: this.parsedGraphData.name,
        zones: [
          {
            value: zoneValue,
            ...linearGradientConfig(controller)
          },
          {
            dashStyle: 'ShortDash',
            ...linearGradientConfig(controller)
          }
        ],
        zoneAxis: 'x'
      }
    ]

    const plotLines = []
    if (total) {
      plotLines.push([
        {
          name: 'Average',
          zIndex: 50,
          color: controller.darkMode
            ? controller.tailwindColors.blue[300]
            : controller.tailwindColors.blue[800],
          value: this.parsedGraphData.average,
          dashStyle: 'Dash',
          width: 1.5,
          label: {
            style: {
              height: '24px'
            },
            x: 0,
            y: 0,
            verticalAlign: 'middle'
          }
        }
      ])

      if (this.optionsValue.goal) {
        plotLines.push({
          zIndex: 50,
          color: controller.darkMode
            ? controller.tailwindColors.yellow[500]
            : controller.tailwindColors.yellow[600],
          value: this.optionsValue.goal.threshold,
          dashStyle: 'Dash',
          width: 1.5,
          label: {
            style: {
              height: '24px'
            },
            x: 0,
            y: 0,
            verticalAlign: 'middle'
          }
        })
      }
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        sync: true,
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        tickPositions: categories,
        labels: {
          step: getDateTimeStepCount(categories?.length || 0),
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: '',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return `${this.value}${controller.parsedGraphData.y_axis_unit}`
            }
          }
        },
        startOnTick: false,
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        series: {
          connectNulls: true,
          cursor: 'pointer',
          stickyTracking: false,
          trackByArea: true,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openDeploymentDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        formatter: function () {
          let date = ''
          if (controller.parsedGraphData.date_range_display) {
            date = controller.parsedGraphData.date_range_display[this.x]
          } else {
            const endDate = controller.getEndDate(
              this.x, controller.optionsValue.date_granularity
            )
            date = `${Highcharts.dateFormat('%b %e', this.x)}` + ' - ' + `${Highcharts.dateFormat('%b %e', endDate)}`
          }

          const unit = this.y === 1 ? controller.parsedGraphData.unit : controller.parsedGraphData.unit_plural
          const dataValue = controller.parsedGraphData.precision ? this.y.toFixed(controller.parsedGraphData.precision) : Math.round(this.y)
          const averageValue = controller.parsedGraphData.precision ? controller.parsedGraphData.average.toFixed(controller.parsedGraphData.precision) : Math.round(controller.parsedGraphData.average)
          let dataValueStr = `${dataValue} ${unit}`
          let avgValueStr = `${averageValue} ${unit}`
          if (controller.parsedGraphData.unit === 'day') {
            dataValueStr = controller.daysOrHoursString(dataValue)
            avgValueStr = controller.daysOrHoursString(averageValue)
          }
          const breakDownValues = [
            breakDownValue({
              name: this.series.name,
              value: dataValueStr,
              style: `background-color: ${this.color};`,
              type: 'line'
            }),
            breakDownValue({
              name: 'Average',
              value: avgValueStr,
              cssClass: 'text-blue-800 dark:text-blue-300',
              type: 'dash'
            })
          ]
          return card({
            date,
            breakDownValues
          })
        },
        style: {
          zIndex: 100
        }
      },
      series
    }
  }
}
